import React from "react"
import { Hero } from "@hemalr/react-blocks"
import styled from "styled-components"
import { primaryColor, secondaryColor } from "../../site-config"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroBg from "../images/foot-print-bg.jpg"
import StyledMain from "../components/styled-main"
import HomeLogos from "../components/home-logos"
import StyledH3 from "../components/styled-h3"
import BookButton from "../components/book-button"
import Navlink from "../styled-components/navlink"

const HeroHeader = styled.h1`
  color: white;
  font-size: 4rem;
  text-align: center;
  line-height: 1em;
  font-weight: 200;
  font-style: normal;
  color: #fff;
  margin: 0;
`

const HeroSubHeader = styled.h2`
  color: white;
  font-size: 2rem;
  font-weight: 100;
  text-align: center;
`

const StyledLi = styled.li`
  margin-bottom: 1rem;
`

const StyledExternalLink = styled.a`
  color: ${primaryColor};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${secondaryColor};
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Perth Foot Medic" href="https://mopodiatry.com.au/" />
    <Hero height="75vh" minHeight="30rem" bgImg={HeroBg}>
      <HeroHeader>Prendiville Ave Podiatry</HeroHeader>
      <HeroSubHeader>Your Podiatry Expert</HeroSubHeader>
      <BookButton text="Book Appointment" size="1.5rem" />
    </Hero>
    <StyledMain>
      <StyledH3>
        At Perth Foot Medic we provide the best evidence based podiatric care
        and management.
      </StyledH3>
      <p>
        Elderly feet, children's feet, sporting feet, from thickened skin to toe
        nail troubles and from diabetic to injured feet, we will look after you.
      </p>
    </StyledMain>
    <StyledMain>
      <StyledH3>
        Reduced (or zero) out of pocket costs via direct insurance and medicare
        claiming
      </StyledH3>
      <ul>
        <StyledLi>
          We are a BUPA preferred provider (
          <StyledExternalLink
            href="https://www.bupa.com.au/find-a-provider/Search-details?id=126251"
            target="_blank"
            rel="noopener noreferrer"
          >
            a part of the Bupa Members First network
          </StyledExternalLink>
          )
        </StyledLi>
        <StyledLi>
          Medicare bulk billed (no gap) with a relevant referal -{" "}
          <Navlink to="/pricing">More details here</Navlink>
        </StyledLi>
        <StyledLi>
          ALL major insurance providers accepted and claimed on the spot -{" "}
          <Navlink to="/pricing">More details here</Navlink>
        </StyledLi>
      </ul>
    </StyledMain>
    <HomeLogos />
    <br />
  </Layout>
)

export default IndexPage
