import React from "react"
import styled from "styled-components"
import { primaryColor } from "../../site-config"

const StyledHeading = styled.h3`
  color: ${primaryColor};
  font-weight: normal;
  font-size: 1.25rem;
`

const StyledH3 = ({ children }) => {
  return <StyledHeading>{children}</StyledHeading>
}

export default StyledH3
